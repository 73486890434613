// @flow
import React from 'react';
import { Link } from 'gatsby';
import kebabCase from 'lodash/kebabCase';
import Layout from '../components/Layout';
import Sidebar from '../components/Sidebar';
import Page from '../components/Page';
import { useSiteMetadata, useTagsList } from '../hooks';

const TagsListTemplate = () => {
  const {
    url: siteUrl,
    title: siteTitle,
    subtitle: siteSubtitle,
    keywords: siteKeywords,
    author: siteAuthor,
    twitter: siteTwitter
  } = useSiteMetadata();

  const tags = useTagsList();

  return (
    <Layout title={`Tags | ${siteTitle}`} description={siteSubtitle} keywords={siteKeywords}
            author={siteAuthor.name} image={`${siteUrl}${siteAuthor.photo}`}
            twitter={siteTwitter}>
      <Sidebar />
      <Page title="Tags">
        <ul>
          {tags.map((tag) => (
            <li key={tag.fieldValue}>
              <Link to={`/articles/tags/${kebabCase(tag.fieldValue)}/`}>
                {tag.fieldValue} ({tag.totalCount})
              </Link>
            </li>
          ))}
        </ul>
      </Page>
    </Layout>
  );
};

export default TagsListTemplate;